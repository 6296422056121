import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "headerLayout",
    component: () => import("../layout/headerLayout.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("../views/Home.vue"),
      },
      {
        path: "/company",
        name: "company",
        component: () => import("../views/Company.vue"),
      },
      {
        path: "/about",
        name: "about",
        component: () => import("../views/About.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
